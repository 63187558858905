import React, { useEffect, useState } from 'react';

import './AllProduct.css';

import QuantitySelector from '../../../Categories/QuantitySelector/QuantitySelector';
import { useCart } from '../../CartContext';
import { Product } from '../../../../types/Products';
import { getPopularProducts } from '../../../../api/requests/ProductRequests';
import { getShopBy } from '../../../../api/requests/ShopByRequests';

const AllProductsPage: React.FC = () => {
  const [popularProducts, setPopularProducts] = useState<Product[]>([]);
  const [shopByData, setShopByData] = useState<any[]>([]); // Replace `any[]` with the appropriate type for ShopBy data
  const { addToCart, updateQuantity, removeFromCart } = useCart();

  // Fetch data on component mount
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch popular products
        const products = await getPopularProducts();
        setPopularProducts(products);

        // Fetch ShopBy data
        const shopBy = await getShopBy();
        setShopByData(shopBy);
      } catch (error) {
        console.error("Erreur lors de la récupération des produits ou ShopBy:", error);
      }
    };

    fetchData();
  }, []); // Empty dependency array to run only once on mount

  const getShopByInfo = (shopBy: string) => {
    // Combine fetched shopByData with the value for better lookup
    return shopByData.find(item => item.label === shopBy) || null;
  };

  const handleAddToCart = (product: Product, quantity: number) => {
    let mediaUrl = '';
    if (product.media instanceof File) {
      mediaUrl = URL.createObjectURL(product.media); // Convert File to a URL string
    } else {
      mediaUrl = product.media; // If it's already a string (URL), use it as is
    }

    const cartItem = {
      id: product.id.toString(),
      name: product.name,
      quantity,
      price: parseFloat(product.price.toString()),
      media: mediaUrl, // Use the media URL (string)
      brand: product.brand,
      unitSize: product.unitSize,
      unitPrice: product.unitPrice,
      shopByIds: product.shopByIds, // Changed to shopByIds based on your product type
    };
    addToCart(cartItem, quantity);
  };
  const handleQuantityChange = (productName: string, quantity: number) => {
    const product = popularProducts.find((p) => p.name === productName);
    if (product) {
      if (quantity > 0) {
        handleAddToCart(product, quantity);
        updateQuantity(productName, quantity);
      } else {
        removeFromCart(productName);
      }
    }
  };

  return (
    <div className="all-products-page">
      <div className="all-products-image-text-container">
        <div className="all-products-image-column">
          <img src="assets/menu/2 1.PNG" alt="Popular Products" className="all-products-image" />
        </div>
        <div className="all-products-text-column">
          <h2>Popular Products</h2>
          <p>Discover all our popular products below!</p>
        </div>
      </div>
      <div className="product-list">
        {popularProducts.length > 0 ? (
          popularProducts.map((product, index) => (
            <div className={`product-item ${index !== popularProducts.length - 1 ? 'border-right' : ''}`} key={product.id}>

              <div className="flex justify-center mb-4">
                {product.media instanceof File ? (
                  product.media.type.startsWith('video') ? (
                    <video
                      src={`http://localhost:45003/api${URL.createObjectURL(product.media)}`}
                      className="product-image"
                      controls
                    />
                  ) : (
                    <img
                      src={`http://localhost:45003/api${URL.createObjectURL(product.media)}`}
                      alt={product.name}
                      className="product-image"
                    />
                  )
                ) : (
                  <img
                    src={`http://localhost:45003/api${product.media}`}
                    alt={product.name}
                    className="product-image"
                  />
                )}
              </div>


              <div className="shop-by-labels">
                {product.shopByIds.map((shopById, i) => {
                  const shopByInfo = shopByData.find((item) => item.id === shopById);
                  return (
                    shopByInfo && (
                      <span key={shopById} style={{ color: shopByInfo.color, fontSize: 14 }}>
                        {shopByInfo.label}
                        {i !== product.shopByIds.length - 1 && <span className="vertical-divider"> | </span>}
                      </span>
                    )
                  );
                })}
              </div>

              <div>
                <p className="product-brand">{product.brand}</p>
                <p className="product-name">{product.name}</p>
              </div>
              <div>
                <p style={{ display: 'flex', alignItems: 'center' }}>
                  <span className="product-unit-size">{product.unitSize}</span>
                  <div className="vertical-line"></div>
                  <span className="original-price">{product.unitPrice}</span>
                </p>
              </div>
              <div className="product-card-container">
                <p>
                  <strong className="current-price">{product.price}</strong>
                </p>
                <div className="right-container">
                  <QuantitySelector
                    initialQuantity={0}
                    onQuantityChange={(quantity) => handleQuantityChange(product.name, quantity)}
                  />
                </div>
              </div>
            </div>
          ))
        ) : (
          <p>Aucun produit populaire disponible.</p>
        )}
      </div>
    </div>
  );
};

export default AllProductsPage;
