
import { Category } from "../../types/Category";
import HTTP_CLIENT from "../client";

const API_ENDPOINT = "categories";

// Récupérer toutes les catégories
export const getCategories = async (): Promise<Category[]> => {
  try {
    const response = await HTTP_CLIENT.get<Category[]>(API_ENDPOINT);
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la récupération des catégories:", error);
    throw error;
  }
};

// Récupérer une catégorie par son ID
export const getCategoryById = async (id: string): Promise<Category> => {
  if (!id) {
    throw new Error("ID is required");
  }
  try {
    const response = await HTTP_CLIENT.get<Category>(`${API_ENDPOINT}/${id}`);
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la récupération de la catégorie:", error);
    throw error;
  }
};

export const getCategoryIdByLabel = async (label: string): Promise<string> => {
  if (!label) {
    throw new Error("Label is required");
  }
  try {
    console.log('Appel API avec label:', label);
    const response = await HTTP_CLIENT.get<string>(`${API_ENDPOINT}/label/${label}`);
    console.log('Réponse de l\'API:', response);

    if (!response.data) {
      throw new Error('Invalid API response: ID not found');
    }

    return response.data; // Retourner l'ID directement
  } catch (error) {
    console.error("Erreur lors de la récupération de l'ID de la catégorie par label:", error);
    throw error;
  }
};



// Récupérer les catégories associées à un MenuData
export const getCategoriesByMenuData = async (menuDataId: string): Promise<Category[]> => {
  if (!menuDataId) {
    throw new Error("MenuData ID is required");
  }
  try {
    const response = await HTTP_CLIENT.get<Category[]>(`${API_ENDPOINT}/menu/${menuDataId}`);
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la récupération des catégories par MenuData:", error);
    throw error;
  }
};

// Créer une nouvelle catégorie
export const createCategory = async (categoryData: any): Promise<Category> => {
  try {
    const response = await HTTP_CLIENT.post<Category>(API_ENDPOINT, categoryData);
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la création de la catégorie:", error);
    throw error;
  }
};

// Mettre à jour une catégorie existante
export const updateCategory = async (id: string, categoryData: any): Promise<Category> => {
  try {
    const response = await HTTP_CLIENT.put<Category>(`${API_ENDPOINT}/${id}`, categoryData);
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la mise à jour de la catégorie:", error);
    throw error;
  }
};

// Supprimer une catégorie
export const deleteCategory = async (id: string): Promise<void> => {
  try {
    await HTTP_CLIENT.delete(`${API_ENDPOINT}/${id}`);
  } catch (error) {
    console.error("Erreur lors de la suppression de la catégorie:", error);
    throw error;
  }
};
