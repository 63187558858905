import React, { useState, useEffect } from 'react';
import './Product.css';
import QuantitySelector from './QuantitySelector/QuantitySelector';
import { useCart } from '../HomePage/CartContext';
import { Product as ProductType } from '../../types/Products';  // Importez le type de produit
import { getShopBy } from '../../api/requests/ShopByRequests';

interface ProductProps {
  products: ProductType[];  // Liste des produits passés en prop
}

const Product: React.FC<ProductProps> = ({ products }) => {
  const { cartItems, addToCart, updateQuantity, removeFromCart } = useCart();
  const [quantities, setQuantities] = useState<{ [key: string]: number }>({});
  const [notification, setNotification] = useState<string | null>(null);
  const [shopByList, setShopByList] = useState<{ id: string, label: string }[]>([]);  // Liste des ShopBy avec id et label
  const [loadingShopBy, setLoadingShopBy] = useState<boolean>(true);  // Indicateur de chargement

  // Récupération des ShopBy depuis le backend
  useEffect(() => {
    const fetchShopBy = async () => {
      try {
        const data = await getShopBy();  // Récupère les données ShopBy depuis le backend
        setShopByList(data);  // Assurez-vous de stocker toute la réponse, y compris id et label
        setLoadingShopBy(false);
      } catch (err) {
        console.error('Erreur lors du chargement des ShopBy:', err);
        setLoadingShopBy(false);
      }
    };

    fetchShopBy();
  }, []);

  // Fonction pour gérer l'ajout au panier
  const handleAddToCart = (product: ProductType, quantity: number) => {
    // Convertir le fichier média en URL
    const mediaUrl = product.media instanceof File ? URL.createObjectURL(product.media) : product.media;

    const cartItem = {
      id: product.id,
      name: product.name,
      quantity,
      price: parseFloat(product.price.toString()), // Assurez-vous que price est un nombre
      media: mediaUrl, // URL ou chemin de fichier média
      brand: product.brand,
      unitSize: product.unitSize,
      unitPrice: product.unitPrice,
      shopByIds: product.shopByIds,  // Utilisation de shopByIds
    };
    addToCart(cartItem, quantity);
    setNotification(`${quantity} x ${product.name} ajouté au panier !`);
    setTimeout(() => setNotification(null), 3000);
  };

  // Fonction pour mettre à jour la quantité d'un produit dans le panier
  const handleQuantityChange = (productName: string, quantity: number) => {
    setQuantities((prev) => ({ ...prev, [productName]: quantity }));

    const product = products.find((p) => p.name === productName);
    if (product) {
      if (quantity > 0) {
        const cartItemExists = quantities[productName] && quantities[productName] > 0;

        if (!cartItemExists) {
          handleAddToCart(product, quantity);
        }

        updateQuantity(productName, quantity);
      } else {
        removeFromCart(productName);
      }
    }
  };

  // Mettre à jour les quantités au démarrage
  useEffect(() => {
    const updatedQuantities: { [key: string]: number } = {};
    cartItems.forEach(item => {
      updatedQuantities[item.name] = item.quantity;
    });
    setQuantities(updatedQuantities);
  }, [cartItems]);

  return (
    <div>
      {notification && <div className="notification">{notification}</div>}
      {loadingShopBy ? (
        <div>Chargement des informations...</div>
      ) : (
        <div className="product-list">
          {products.length > 0 ? (
            products.map((product) => (
              <div className="product-item" key={product.id}>
                <div className="flex justify-center mb-4">
                  {product.media instanceof File ? (
                    product.media.type.startsWith('video') ? (
                      <video
                        src={`http://localhost:45003/api${URL.createObjectURL(product.media)}`}
                        className="product-image"
                        controls
                      />
                    ) : (
                      <img
                        src={`http://localhost:45003/api${URL.createObjectURL(product.media)}`}
                        alt={product.name}
                        className="product-image"
                      />
                    )
                  ) : (
                    <img
                      src={`http://localhost:45003/api${product.media}`}
                      alt={product.name}
                      className="product-image"
                    />
                  )}
                </div>

                <div className="shop-by-labels">
                  {product.shopByIds && product.shopByIds.length > 0 && product.shopByIds.map((shopById, i) => {
                    // Trouver le label correspondant dans shopByList
                    const shopBy = shopByList.find(item => item.id === shopById);
                    return (
                      shopBy && (
                        <span key={shopBy.id} style={{ fontSize: 14 }}>
                          {shopBy.label}
                          {i !== product.shopByIds.length - 1 && <span className="vertical-divider"> | </span>}
                        </span>
                      )
                    );
                  })}
                </div>

                <div>
                  <p className="product-brand">{product.brand}</p>
                  <p className="product-name">{product.name}</p>
                </div>
                <div>
                  <p style={{ display: 'flex', alignItems: 'center' }}>
                    <span className="product-unit-size">{product.unitSize}</span>
                    <div className="vertical-line"></div>
                    <span className="original-price">{product.unitPrice} XOF/lb</span>
                  </p>
                </div>
                <div className="product-card-container">
                  <p>
                    <strong className="current-price">{product.price} XOF/ea</strong>
                  </p>
                  <div className="right-container">
                    <QuantitySelector
                      initialQuantity={quantities[product.name] || 0}
                      onQuantityChange={(quantity) => handleQuantityChange(product.name, quantity)}
                    />
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p>Aucun produit disponible.</p>
          )}
        </div>
      )}
    </div>
  );
};

export default Product;
