import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './DepartmentsSection.css';
import { getMenuDataByNavItemId } from '../../api/requests/menuDataRequests';
import { getCategoriesByMenuData } from '../../api/requests/categorieRequests';

interface Category {
  label: string;
  subcategories?: string[];
}

interface MenuData {
  label: string;
  categories: (string | Category)[];
  image: string | File;
}

interface DepartmentsSectionProps {
  navItemId: string; // Ajout de l'ID du navItem pour récupérer les données
}

const DepartmentsSection: React.FC<DepartmentsSectionProps> = ({ navItemId }) => {
  const [menuData, setMenuData] = useState<MenuData[]>([]);
  const [categories, setCategories] = useState<Category[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>('');

  useEffect(() => {
    if (navItemId) {
      setLoading(true);
      fetchMenuData();
    } else {
      setLoading(false);
    }
  }, [navItemId]);

  const fetchMenuData = async () => {
    if (!navItemId) return;
    try {
      const data = await getMenuDataByNavItemId(navItemId);
      if (Array.isArray(data) && data.length > 0) {
        setMenuData(data);
        fetchCategoriesForMenuData(data[0].id);
      } else {
        setError('Aucune donnée trouvée pour ce navItemId.');
      }
      setLoading(false);
    } catch (error) {
      console.error('Erreur lors de la récupération des MenuData:', error);
      setError('Erreur lors de la récupération des MenuData');
      setLoading(false);
    }
  };
  const formatLabelForUrl = (label: string) => {
    return label
      .toLowerCase()
      .replace(/ /g, '-')
      .replace(/&/g, '-')
      .replace(/-+/g, '-')
      .replace(/^-|-$/g, '');
  };
  
  const fetchCategoriesForMenuData = async (menuId: string) => {
    try {
      const data = await getCategoriesByMenuData(menuId);
      setCategories(data);
    } catch (error) {
      console.error('Erreur lors de la récupération des catégories:', error);
      setError('Erreur lors de la récupération des catégories');
    }
  };

  if (loading) {
    return <div>Chargement...</div>;
  }

  if (error) {
    return <div className="error-message">{error}</div>;
  }

  if (!menuData || menuData.length === 0) {
    return <div className="error-message">Aucune donnée disponible pour ce département.</div>;
  }

  const sectionPairs = [];
  for (let i = 0; i < menuData.length; i += 2) {
    sectionPairs.push(menuData.slice(i, i + 2));
  }

  return (
    <div className="departments-section">
      <div className="main-department-header">
        <h1 className="main-department-title">Ready-to-eat: {menuData.map(section => section.label).join(', ')}</h1>
      </div>
      <hr className="section-divider" />

      {sectionPairs.map((pair, pairIndex) => (
        <div key={pairIndex} className="departments-row">
          {pair.map((section: MenuData, sectionIndex) => {
            const sectionCategories = section.categories || [];
           
           
            return (
              <div key={sectionIndex} className={`department-section ${sectionIndex % 2 === 0 ? 'image-right' : 'image-left'}`}>
                <h2 className="department-title">{section.label}</h2>
                <div className="categories-container">
                  <div className="categories-list">
                    {categories.slice(0, 6).map((category, catIndex) => (
                      <span key={catIndex} className="category-item">
                        {category.label} {/* Afficher le label des catégories récupérées */}
                        {catIndex < 5 && ', '}
                      </span>
                    ))}
                  </div>
                  <Link
  to={`/${navItemId}/${formatLabelForUrl(section.label)}`} // Include both navItemId and the formatted menuData label
  className="view-all-button"
>
  View All
</Link>

                  {section.image && (
                    <img
                      src={`http://localhost:45003/api${section.image}`}
                      alt={section.label}
                      className="department-image"
                    />
                  )}
                </div>
              </div>
            );
          })}
        </div>
      ))}
    </div>
  );
};

export default DepartmentsSection;
