import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { getCategoriesByMenuData } from '../api/requests/categorieRequests';
import { getMenuDataByNavItemId } from '../api/requests/menuDataRequests';
import { getNavItemById } from '../api/requests/navItemRequests';
import { Category } from '../types/Category';
import './DepartmentsPage2.css';

interface DepartmentsPage2Props {
  navItemLabel: string;
  menuDataLabel: string;
}

const DepartmentsPage2: React.FC = () => {
  const { navItemId, menuDataLabel } = useParams<{ navItemId: string; menuDataLabel: string }>();
  const [categories, setCategories] = useState<Category[]>([]);
  const [menuData, setMenuData] = useState<any>(null);
  const [navItemLabel, setNavItemLabel] = useState<string>('');

  useEffect(() => {
    if (!navItemId) {
      console.error("NavItem ID is undefined");
      return;
    }

    const fetchNavItem = async () => {
      try {
        const navItem = await getNavItemById(navItemId);
        setNavItemLabel(navItem?.label || '');
      } catch (error) {
        console.error("Error fetching NavItem:", error);
      }
    };

    const fetchMenuData = async () => {
      try {
        const data = await getMenuDataByNavItemId(navItemId);
        setMenuData(data); // Assurez-vous que 'data' est un tableau
        if (data && data.length > 0 && data[0]?.id) { // Vérifiez si l'élément a un 'id'
          fetchCategoriesForMenuData(data[0].id); // Utilisez data[0].id si vous travaillez avec un tableau
        }
      } catch (error) {
        console.error("Error fetching MenuData:", error);
      }
    };

    const fetchCategoriesForMenuData = async (menuId: string) => {
      try {
        const data = await getCategoriesByMenuData(menuId);
        setCategories(data);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchNavItem();
    fetchMenuData();
  }, [navItemId]);

  const generateUrl = (categoryLabel: string) => {
    // Génère une URL avec navItemLabel, menuDataLabel, et categoryLabel
    return `/category/${navItemLabel}/${menuDataLabel}/${categoryLabel.toLowerCase().replace(/ /g, '-').replace(/&/g, 'and')}`;
  };

  const generateColumns = (categories: Category[]) => {
    const maxItemsPerColumn = 4;
    const columns = [];

    for (let i = 0; i < categories.length; i += maxItemsPerColumn) {
      columns.push(categories.slice(i, i + maxItemsPerColumn));
    }

    return columns;
  };

  return (
    <div className="category-page">
      <nav aria-label="breadcrumb" className="breadcrumbs">
        <ol className="bread">
          <li className="breadcrumb-item">
            <Link to={`/department/${navItemId}`}>{navItemLabel}</Link> {/* Redirige vers /department/:navItemId */}
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            <span>{menuDataLabel}</span> {/* Affiche le label du premier élément */}
          </li>

        </ol>
      </nav>

      <div className="categories-grid">
        {generateColumns(categories).map((column, columnIndex) => (
          <div key={columnIndex} className="category-column">
            {column.map((category) => (
              <Link
                key={category.id}
                to={generateUrl(category.label)}
                className="category-link"
              >
                {category.label}
              </Link>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default DepartmentsPage2;
