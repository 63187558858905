
import { MenuData } from "../../types/MenuData";
import HTTP_CLIENT from "../client";

const API_ENDPOINT = "menuData";

// Récupérer tous les MenuData
// Récupérer tous les MenuData
export const getMenuData = async (): Promise<MenuData[]> => {
  try {
    const response = await HTTP_CLIENT.get<MenuData[]>(API_ENDPOINT);
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la récupération des MenuData:", error);
    throw error;
  }
};


// Récupérer un MenuData par son ID
export const getMenuDataById = async (id: string): Promise<MenuData> => {
  if (!id) {
    throw new Error("ID is required");
  }
  try {
    const response = await HTTP_CLIENT.get<MenuData>(`${API_ENDPOINT}/${id}`);
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la récupération du MenuData:", error);
    throw error;
  }
};

// Récupérer les MenuData par NavItem ID (nouvelle fonction ajoutée)
export const getMenuDataByNavItemId = async (navItemId: string): Promise<MenuData[]> => {
  if (!navItemId) {
    throw new Error("NavItem ID is required");
  }
  try {
    const response = await HTTP_CLIENT.get<MenuData[]>(`${API_ENDPOINT}/byNavItem/${navItemId}`);
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la récupération des MenuData pour le NavItem:", error);
    throw error;
  }
};

// Créer un nouveau MenuData
export const createMenuData = async (formData: FormData): Promise<MenuData> => {
  try {
    const response = await HTTP_CLIENT.post<MenuData>(API_ENDPOINT, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la création du MenuData:", error);
    throw error;
  }
};

// Mettre à jour un MenuData existant
export const updateMenuData = async (id: string, formData: FormData): Promise<MenuData> => {
  try {
    const response = await HTTP_CLIENT.put<MenuData>(`${API_ENDPOINT}/${id}`, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la mise à jour du MenuData:", error);
    throw error;
  }
};

// Supprimer un MenuData
export const deleteMenuData = async (id: string): Promise<void> => {
  try {
    await HTTP_CLIENT.delete(`${API_ENDPOINT}/${id}`);
  } catch (error) {
    console.error("Erreur lors de la suppression du MenuData:", error);
    throw error;
  }
};

