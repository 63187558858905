
import ShopBy from "../../types/ShopBy";
import HTTP_CLIENT from "../client";

const API_ENDPOINT = "shopby";

// Récupérer tous les ShopBy
export const getShopBy = async (): Promise<ShopBy[]> => {
  try {
    const response = await HTTP_CLIENT.get<ShopBy[]>(API_ENDPOINT);
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la récupération des ShopBy:", error);
    throw error;
  }
};
// Récupérer un ShopBy par son ID
export const getShopByById = async (id: string): Promise<ShopBy> => {
    if (!id) {
      throw new Error("ID is required");
    }
    try {
      const response = await HTTP_CLIENT.get<ShopBy>(`${API_ENDPOINT}/${id}`);
      return response.data;
    } catch (error) {
      console.error("Erreur lors de la récupération du ShopBy:", error);
      throw error;
    }
  };
// Créer un nouveau ShopBy
export const createShopBy = async (shopByData: any): Promise<ShopBy> => {
    try {
      const response = await HTTP_CLIENT.post<ShopBy>(API_ENDPOINT, shopByData);
      return response.data;
    } catch (error) {
      console.error("Erreur lors de la création du ShopBy:", error);
      throw error;
    }
  };
// Mettre à jour un ShopBy existant
export const updateShopBy = async (id: string, shopByData: any): Promise<ShopBy> => {
    try {
      const response = await HTTP_CLIENT.put<ShopBy>(`${API_ENDPOINT}/${id}`, shopByData);
      return response.data;
    } catch (error) {
      console.error("Erreur lors de la mise à jour du ShopBy:", error);
      throw error;
    }
  };
// Supprimer un ShopBy
export const deleteShopBy = async (id: string): Promise<void> => {
    try {
      await HTTP_CLIENT.delete(`${API_ENDPOINT}/${id}`);
    } catch (error) {
      console.error("Erreur lors de la suppression du ShopBy:", error);
      throw error;
    }
  };
        