import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import Breadcrumbs from '../Components/Categories/Breadcrumbs/Breadcrumbs';
import Product from '../Components/Categories/Product';
import SubcategoryList from '../Components/Categories/SubCategoriesList/SubCategoriesList';
import { getProductsBySubcategory } from '../api/requests/ProductRequests';
import { getSubcategoriesByCategory } from '../api/requests/subcategoryRequests';
import { getCategoryIdByLabel } from '../api/requests/categorieRequests';
import { getNavItemByLabel } from '../api/requests/navItemRequests';

const CategoryPage: React.FC = () => {
  const [selectedSubcategory, setSelectedSubcategory] = useState<string>('');
  const [products, setProducts] = useState<any[]>([]);
  const [subcategories, setSubcategories] = useState<{ label: string, id: string }[]>([]); // Modifier pour stocker aussi l'ID
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [categoryId, setCategoryId] = useState<string>('');
  const [navItemId, setNavItemId] = useState<string>('');

  const { categoryLabel} = useParams();
  const location = useLocation();

  const navItemLabel = location.pathname.split('/')[2];
  const menuDataLabel = location.pathname.split('/')[3];
 // Affichage des labels pour vérifier les valeurs
 console.log("NavItem Label:", navItemLabel);
 console.log("Category Label:", categoryLabel);

 // Fonction pour récupérer l'ID du NavItem
 const fetchNavItemId = async () => {
  if (navItemLabel) {
    try {
      const navItemId = await getNavItemByLabel(navItemLabel);  // Cela retourne l'UUID directement
      console.log("NavItem ID fetched:", navItemId); // Vérification de l'ID
      setNavItemId(navItemId); // Mise à jour de l'état navItemId
    } catch (err) {
      console.error('Erreur lors de la récupération de l\'ID du navItem:', err);
    }
  }
};

  const breadcrumbs = [
    { label: navItemLabel || '', path: `/department/${navItemId}` },
    { label: menuDataLabel || '', path: `/${navItemId}/${menuDataLabel}` },
    { label: categoryLabel || '', path: location.pathname },
  ];

  const fetchCategoryData = async () => {
    if (!categoryLabel) return;

    setLoading(true);
    setError('');

    try {
      // Fetch category ID
      const id = await getCategoryIdByLabel(categoryLabel);
      setCategoryId(id);

      // Fetch subcategories with their IDs
      const subcategoryData = await getSubcategoriesByCategory(id);
      const subcategoryLabelsWithIds = subcategoryData.map((sub) => ({
        label: sub.label,
        id: sub.id,
      }));
      setSubcategories(subcategoryLabelsWithIds);

      // Fetch products for all subcategories
      const productPromises = subcategoryLabelsWithIds.map((subcategory) =>
        getProductsBySubcategory(subcategory.id) // Utilisation de l'ID de la sous-catégorie
      );
      const productResults = await Promise.all(productPromises);
      setProducts(productResults.flat());
    } catch (err) {
      console.error('Erreur lors de la récupération des données de catégorie:', err);
      setError('Erreur lors du chargement des données.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchNavItemId(); 
    fetchCategoryData();
  }, [categoryLabel]);

  const handleSubcategoryClick = async (label: string) => {
    setSelectedSubcategory(label);

    // Récupérer l'ID de la sous-catégorie sélectionnée
    const subcategory = subcategories.find(sub => sub.label === label);
    if (subcategory) {
      // Utiliser l'ID pour récupérer les produits associés
      const products = await getProductsBySubcategory(subcategory.id);
      setProducts(products);
    }
  };

  return (
    <main>
      {/* Affichage des breadcrumbs */}
      <Breadcrumbs breadcrumbs={breadcrumbs} totalProducts={products.length} />

      {/* Affichage de la liste des sous-catégories */}
      <SubcategoryList
        categoryLabel={categoryLabel || ''}
        subcategories={subcategories.map(sub => sub.label)} // Utilisation des labels des sous-catégories
        onSubcategoryClick={handleSubcategoryClick} // Mise à jour avec la nouvelle fonction
      />

      {/* Message de chargement */}
      {loading && <p>Chargement des produits...</p>}

      {/* Affichage de l'erreur */}
      {error && <p className="error-message">{error}</p>}

      {/* Affichage des produits ou message si aucun produit */}
      {!loading && !error && products.length === 0 && (
        <p>Aucun produit disponible pour cette sous-catégorie.</p>
      )}

      {!loading && !error && products.length > 0 && (
        <Product products={products} />
      )}
    </main>
  );
};

export default CategoryPage;
