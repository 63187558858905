import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import DepartmentsSection from '../Components/DepartmentsSection/DepartmentsSection';
import ShopByMenu from '../Components/HomePage/MainContent/ShopBy/ShopByMenu';
import { ShopByData } from '../Components/HomePage/MainContent/ShopBy/ShopByData';
import ShopBy from '../types/ShopBy';

const DepartmentPage: React.FC = () => {
  // Récupérer le paramètre navItemId depuis l'URL
  const { navItemId } = useParams<{ navItemId: string }>();
  const [items, setItems] = useState<ShopBy[]>([]);
  const [activeItemId, setActiveItemId] = useState<string | null>(null); // Use string here

  const handleClick = (id: string) => { // Use string here
    setActiveItemId(id);
  };

  return (
    <div className="department-page">
      <ShopByMenu
        items={items}
        activeItemId={activeItemId}
        onClick={handleClick} // No error now
      />
      {/* Afficher DepartmentsSection seulement si navItemId est défini */}
      {navItemId && <DepartmentsSection navItemId={navItemId} />}
    </div>
  );
};

export default DepartmentPage;



