import { Subcategory } from "../../types/Subcategory";
import HTTP_CLIENT from "../client";

const API_ENDPOINT = "subcategories";

// Récupérer toutes les sous-catégories
export const getSubcategories = async (): Promise<Subcategory[]> => {
  try {
    const response = await HTTP_CLIENT.get<Subcategory[]>(API_ENDPOINT);
    return response.data; // Retourne les sous-catégories avec leur catégorie associée
  } catch (error) {
    console.error("Erreur lors de la récupération des sous-catégories:", error);
    throw error;
  }
};

// Récupérer une sous-catégorie par son ID
export const getSubcategoryById = async (id: string): Promise<Subcategory> => {
  if (!id) {
    throw new Error("ID is required");
  }
  try {
    const response = await HTTP_CLIENT.get<Subcategory>(`${API_ENDPOINT}/${id}`);
    return response.data; // Retourne la sous-catégorie avec la catégorie associée
  } catch (error) {
    console.error("Erreur lors de la récupération de la sous-catégorie:", error);
    throw error;
  }
};
// Fonction pour récupérer les sous-catégories d'une catégorie spécifique
export const getSubcategoriesByCategory = async (categoryId: string): Promise<Subcategory[]> => {
  try {
    const response = await HTTP_CLIENT.get<Subcategory[]>(`${API_ENDPOINT}/subcategory/${categoryId}`);
    return response.data; // Retourne les sous-catégories liées à la catégorie spécifique
  } catch (error) {
    console.error("Erreur lors de la récupération des sous-catégories pour cette catégorie:", error);
    throw error;
  }
};


// Créer une nouvelle sous-catégorie
export const createSubcategory = async (subcategory: Subcategory): Promise<Subcategory> => {
  try {
    const response = await HTTP_CLIENT.post<Subcategory>(API_ENDPOINT, {
      label: subcategory.label,
      categoryId: subcategory.categoryId // On passe l'ID de la catégorie ici
    });
    return response.data; // Retourne la sous-catégorie nouvellement créée
  } catch (error) {
    console.error('Erreur lors de la création de la sous-catégorie :', error);
    throw error;
  }
};

// Mettre à jour une sous-catégorie existante
export const updateSubcategory = async (id: string, subcategory: Subcategory): Promise<Subcategory> => {
  try {
    const response = await HTTP_CLIENT.put<Subcategory>(`${API_ENDPOINT}/${id}`, {
      label: subcategory.label,
      categoryId: subcategory.categoryId // Mise à jour de l'ID de la catégorie si nécessaire
    });
    return response.data; // Retourne la sous-catégorie mise à jour
  } catch (error) {
    console.error("Erreur lors de la mise à jour de la sous-catégorie:", error);
    throw error;
  }
};

// Supprimer une sous-catégorie
export const deleteSubcategory = async (id: string): Promise<void> => {
  try {
    await HTTP_CLIENT.delete(`${API_ENDPOINT}/${id}`);
  } catch (error) {
    console.error("Erreur lors de la suppression de la sous-catégorie:", error);
    throw error;
  }
};
